:root {
    --primary-color: #00a5ce;
    --secondary-color: #89c97f;
    --accent-color: #4db1b3;
    --light-color: #95d3c3;
    --light-blue-color: #64acc4;
    --brown-color: #5c5d60;
    --text-cleanar-color: #374357;
    --accordion-bg-color: #acb9c7;
}

.quick-quote-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background: var(--light-color);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.quick-quote-container h2 {
    margin-bottom: 15px;
    color: var(--primary-color);
    font-weight: bold;
}

.quick-quote-form {
    opacity: 0.9;
}

.form-input {
    border-radius: 2 !important;
    border: 1px solid var(--primary-color)!important;
    padding: 8px;
    margin-bottom: 10px;
    width: 100%;
}

.service-selection,
.options-selection {
    margin: 15px 0;
    text-align: left;
}

.radio-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 5px;
}

.radio-label {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-size: 16px;
}

input[type="radio"] {
    accent-color: var(--primary-color);
    width: 18px;
    height: 18px;
}

button {
    width: 100%;
    padding: 10px;
    background: var(--secondary-color);
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 15px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

button:hover {
    background: var(--accent-color);
}

button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.description-textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--text-cleanar-color);
    margin: 10px 0;
}

.text-cleanar-color {
    color: var(--text-cleanar-color) !important;
    font-weight: bold;
}

.text-bold {
    font-weight: bold !important;
}

.transparent {
    background-color: transparent !important;
}

.service-button-residential {
    background-color: var(--light-blue-color) !important;
    color: white;
}

.service-button-commercial {
    background-color: var(--primary-color) !important;
    color: white;
}

.service-button-carpet-cleaning {
    background-color: var(--accent-color) !important;
    color: white;
}

.service-section-residential {
    background-color: var(--light-blue-color);
}

.service-section-commercial {
    background-color: var(--primary-color);
}

.service-section-industrial {
    background-color: var(--accent-color);
}

.service-section-carpet-cleaning {
    background-color: var(--accent-color);
}

/* General Styles */

.request-quote-bg {
    background-color: var(--accordion-bg-color) !important;
}

.p-styling {
    color: rgb(32, 30, 30);
    font-size: 1.2rem;
    background-color: var(--light-color);
    padding: 10px;
    border-radius: 10px;
}

/* Text Styles */
.text-cleanar-bg-color {
    background-color: var(--text-cleanar-color) !important;
}

.primary-color {
    color: var(--primary-color) !important;
}

.primary-bg-color {
    background-color: var(--primary-color) !important;
}

.secondary-color {
    color: var(--secondary-color) !important;
}

.secondary-bg-color {
    background-color: var(--secondary-color) !important;
}

.accent-color {
    color: var(--accent-color) !important;
}

.accent-bg-color {
    background-color: var(--accent-color) !important;
}

.light-color {
    color: var(--light-color) !important;
}

.light-bg-color {
    background-color: var(--light-color) !important;
}

.opacity-8 {
    opacity: 0.8;
}

/* Font Styles */
.montserrat-bold {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.alata-regular {
    font-family: "Alata", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.martel-extralight {
    font-family: "Martel", serif;
    font-weight: 200;
    font-style: normal;
}

.martel-light {
    font-family: "Martel", serif;
    font-weight: 300;
    font-style: normal;
}

.martel-regular {
    font-family: "Martel", serif;
    font-weight: 400;
    font-style: normal;
}

.martel-semibold {
    font-family: "Martel", serif;
    font-weight: 600;
    font-style: normal;
}

.martel-bold {
    font-family: "Martel", serif;
    font-weight: 700;
    font-style: normal;
}

.martel-extrabold {
    font-family: "Martel", serif;
    font-weight: 800;
    font-style: normal;
}

.martel-black {
    font-family: "Martel", serif;
    font-weight: 900;
    font-style: normal;
}

/* Modal Styles */
.modal-header-text {
    background-color: #fb8500 !important;
    font-size: 18px;
    color: white;
    text-align: center !important;
}

.modal-body-text {
    background-color: #fb8500 !important;
    font-size: 15px;
    font-weight: bold;
    color: white;
    text-align: center !important;
}

.modal-footer {
    background-color: #ffb703 !important;
    font-size: 15px;
    font-weight: bold;
    color: white;
    text-align: center !important;
}

.modal-link {
    color: var(--text-cleanar-color) !important;
    font-weight: bold;
}

.modal-link:hover {
    color: var(--accent-color) !important;
}

.modal-btn {
    color: #8ecae6 !important;
    background-color: #023047 !important;
    font-weight: bold !important;
}

/* Navbar Styles */
.navbar-color {
    background-color: var(--light-color);
}

/* Logo Styles */
.logo-size {
    height: 250px;
}

/* Contact Section */
.contact-info i {
    color: var(--accent-color);
    margin-right: 10px;
}

/* Accordion Styles */
.accordion-header {
    background-color: var(--accordion-bg-color) !important;
    border: none;
}

.accordion-header span {
    color: var(--brown-color);
    font-size: 0.9rem;
    font-weight: bold;
}

/* Section Styles */
.section {
    padding: 20px;
    margin-bottom: 30px;
}

/* Card Styles */
.card-border {
    border: 1px solid var(--accent-color) !important;
    background: transparent !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-border:hover {
    transform: translateY(-5px);
    transition: 0.3s;
}

.card-plain {
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-header {
    font-weight: bold;
}

.card-title {
    color: var(--accent-color);
}

/* Background Colors */
.light-blue-bg-color {
    background-color: var(--light-blue-color) !important;
}

.gradient-bg {
    background: linear-gradient(45deg, var(--light-blue-color), var(--light-color));
    color: white;
}

.brown-bg-color {
    background-color: var(--brown-color) !important;
}

/* Content Row Layout */
.content-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* Logo Column */
.logo-col {
    display: flex;
    justify-content: left;
    align-items: left;
}

.logo-image {
    max-width: 300px;
    height: auto;
}

@media (min-width: 768px) {
    .logo-image {
        max-width: 400px;
    }
}

@media (min-width: 1200px) {
    .logo-image {
        max-width: 500px;
    }
}

.logo-image-index {
    max-width: 300px;
    height: auto;
}

@media (min-width: 768px) {
    .logo-image-index {
        max-width: 300px;
    }
}

@media (min-width: 1200px) {
    .logo-image-index {
        max-width: 300px;
    }
}

/* Text Column */
.text-col {
    display: flex;
    align-items: center;
}

.text-content {
    font-size: 1rem;
    line-height: 1.6;
    text-align: justify;
}

@media (min-width: 768px) {
    .text-content {
        font-size: 1.1rem;
    }
}

@media (min-width: 1200px) {
    .text-content {
        font-size: 1.2rem;
    }
}

/* Chatbot Styles */
#chat-area {
    margin: 0 auto;
    width: 300px;
    height: 400px;
    overflow: scroll;
    border: 1px solid gray;
    border-radius: 4px;
}

.input {
    width: 100%;
}

.submit-area {
    justify-content: center;
    display: flex;
    margin: 20px auto;
    width: 300px;
}

textarea {
    width: 100%;
}

.box {
    width: 96%;
    margin: 0 auto;
    padding: 10px;
    background-color: #C4DBFE;
    margin: 10px auto;
}

.answer {
    background-color: aquamarine;
}

.chatbot-toggle {
    position: fixed;
    bottom: 20px;
    right: 80px;
    background: var(--primary-color);
    border-radius: 50%;
    padding: 10px;
    border: none;
    cursor: pointer;
    z-index: 1000;
}

.chatbot-toggle img {
    width: 40px;
    height: 40px;
}

.chatbot-window {
    position: fixed;
    bottom: 70px;
    right: 20px;
    width: 300px;
    height: 400px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

/* WhatsApp Button */
.whatsapp-btn {
    position: fixed;
    bottom: 10px;
    right: 20px;
    background: #25D366;
    border-radius: 50%;
    padding: 10px;
    border: none;
    cursor: pointer;
    z-index: 1000;
    animation: bounce 2s infinite;
}

.whatsapp-btn:hover {
    animation: none;
    transform: scale(1.1);
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
    40% { transform: translateY(-15px); }
    60% { transform: translateY(-10px); }
}

.content {
    padding: 20px;
    justify-content: center;
}

.content-border {
    border: 1px solid var(--accent-color);
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
}

/* Footer Styles */
.footer {
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--primary-color) !important;
    color: white;
    text-align: center;
    padding: 10px;
    z-index: 1000;
}

/* Navbar Styles */
.nav-bar-top {
    position: sticky;
    top: 0;
    z-index: 1000;
    margin-bottom: 0px !important;
}

.navbar-top {
    position: sticky;
    top: 0;
}

/* Section Background Styles */
.section-background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 20px;
    position: relative;
    z-index: 1;
}

.section-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

.index-section {
    background-image: url('../img/stock-photo-high-angle-view-person-cleaning-white-carpet-professional-vacuum-cleaner.jpg');
}

.products-services-section {
    background-image: url('../img/stock-photo-high-angle-view-person-cleaning-white-carpet-professional-vacuum-cleaner.jpg');
}

/* Carousel Styles */
.carousel {
    box-shadow: none;
    border: none;
    padding: 0;
    max-height: max-content;
}

.promo-slide-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 40px 60px;
    position: relative;
}

.carousel-control-prev, .carousel-control-next {
    z-index: 2;
}

.promo-slide {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.slide-title {
    font-size: 22px;
    font-weight: bold;
}

.winter-title {
    color: red !important;
}

.fall-saving-slide {
    background-color: #fb8500 !important;
}

.winter-saving-slide {
    background-color: green !important;
}

.new-year-saving-p {
    font-size: 18px;
    font-weight: bold;
    color: black;
}

.new-year-slide {
    background-color: gold !important;
}

.request-quote-slide, 
.display-review-slide {
    background-color: var(--secondary-color) !important;
}

.follow-us-slide, .review-slide {
    background-color: var(--accent-color) !important;
}

.work-with-us-slide {
    background-color: var(--light-color) !important;
}

.slide-link {
    color: var(--text-cleanar-color) !important;
    font-weight: bold;
}

.form-check-sign {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 10px;
    position: relative;
  }
  
  .form-check-sign::after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  .form-check-input:checked ~ .form-check-sign::after {
    display: block;
  }
  
  .form-check-input {
    display: none;
  }